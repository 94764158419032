function convertStyleTo2D(styles) {
  let styles2D = new Array();
  for(var i=0;i<=styles[styles.length-1].hierarchy;i++){
      let filterArr=styles.filter(function(current,index,arr){
          if(current.hierarchy===i) return current;
      });
  styles2D.push(filterArr);
  }
  return styles2D
}
export { convertStyleTo2D }